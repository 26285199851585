<template>
<div class="meg_all">
    <div class="meg">
    <h1>权限管理</h1>
    <div class="meg_g">
        <div class="meg_g_1">当前{{leibie}}：<span>{{titleName}}</span></div>
        <div class="meg_g_2">
        <table v-for="item in NavPowList" :key="item.id">
          <colgroup>
            <col style="width:20%" />
          </colgroup>
          <thead>
            <tr>
              <th align="left">{{item.name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="itemson in item.sonNav" :key="itemson.id">
                  <td align="left"><label><input type="checkbox" v-model="selectPow" :value="itemson.id" @change="selectAll($event,itemson)">{{itemson.name}}</label></td>
                  <td>
                      <div class="meg_g_2_1" v-for="itemsun in itemson.pow" :key="itemsun">
                          <label><input type="checkbox" v-model="selectPow" @change="selectOne($event,itemson)" :value="itemsun.id">{{itemsun.name}}</label>
                          <div class="clear"></div>
                      </div>
                  </td>
              </tr>
          </tbody>
        </table>
    </div>
    </div>
    <div class="meg_c"><button type="button" class="off" @click="closdMeg()">取消</button><button type="button" class="sub" @click="emitPows">确定</button></div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
    </div>
</div>
</template>

<script>
import axios from '@/http/axios'
import {NavPowList} from '@/data/data'
export default {
  name: 'home',
  props: {leibie:String,titleName:String, pow: Array,shareId:Number},
  data () {
    return {
      NavPowList:NavPowList,
      selectPow:this.pow.length?this.pow:[],
    }
  },
  created () {
  },
  mounted () { },
  components: {},
  methods: {
    init(){
    },
    selectAll(e,itemson){
      if(e.target.checked){
        itemson.pow.forEach(e=>{
         let num=this.selectPow.findIndex(a=>a==e.id)
         if(num==-1) this.selectPow.push(e.id)
        })
      }else{
        itemson.pow.forEach(e=>{
         let num=this.selectPow.findIndex(a=>a==e.id)
         if(num>=0) this.selectPow.splice(num,1)
        })
      }
    },
    selectOne(e,itemson){
      if(e.target.checked){
        if(e.target.parentElement.innerText!='查看'){
          let sun= itemson.pow.find(a=>a.name=='查看')
          if(sun&&!this.selectPow.includes(sun.id)) this.selectPow.push(sun.id)
        }
        let on=true
        itemson.pow.forEach(b => {
          if(!this.selectPow.includes(b.id)) on=false
        })
        if(on) this.selectPow.push(itemson.id)
      }else{
        if(e.target.parentElement.innerText=='查看'){
          let sun= itemson.pow.find(a=>a.name=='查看')
          let on=false
          itemson.pow.forEach(b => {
            if(this.selectPow.includes(b.id)) on=true
          })
          if(sun&&on) {
            this.selectPow.push(sun.id)
            this.$Mesg({
              content: '有其他权限的情况下，不能取消查看权限！',
              errOrSuc: 'war',
            })
          }else{
            let num =this.selectPow.findIndex(a=>a==itemson.id)
            if(num>=0) this.selectPow.splice(num,1)
          }
        }else{
          let num =this.selectPow.findIndex(a=>a==itemson.id)
          if(num>=0) this.selectPow.splice(num,1)
        }
      }
    },
    emitPows () {
      axios.post('/share/pow',{pow:this.selectPow.toString(),id:this.shareId,shareName:this.titleName}).then((res) => {
        if(res.code===5000){
          this.$Mesg({content:'设置权限成功！重新登录后权限生效！'})
          this.$parent.getData(1)
          this.closdMeg()
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
