<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>级别名称：</p>
          <input type="text" v-model="form.shareName" @keypress.enter="getData(1)">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>编号</td>
            <td>级别名称</td>
            <td>指定加成(比例)</td>
            <td>指定加成(固定)</td>
            <td>分配门店</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.id}}</td>
            <td>{{item.shareName}}</td>
            <td>{{item.tichengbaifenbi}}%</td>
            <td>{{item.tichengguding}}元</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" v-if="countPow(item.fenpeimendianId)" @click="setInfoData(item)" v-pow:2302>管理</button>
                <button type="button" class="pur" @click="setPow(item)" v-pow:9999>权限管理</button>
                <button type="button" class="err" v-if="countPow(item.fenpeimendianId)" @click="delOneData(item.id,item.shareName)" v-pow:2303>删除</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2301>新增级别</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
    <Pow @closdMeg="powShow=false" :titleName="titleName" :leibie="leibie" :shareId="shareId" :pow="pow" v-if="powShow"></Pow>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import Pow from '../../components/pow'

export default {
  name: 'packageSettings',
  data() {
    return {
      pow:[],
      title:'',
      titleName:'',
      shareId:null,
      leibie:'级别',
      powShow:false,
      infoShow: false,
      dataList:[],
      storeList:[],
      form:{
        shareName:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
     this.getData(1)
     this.getStore()
  },
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  components: {PSinfo,Pow},
  mounted() {},
  methods: {
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?（删除级别并不会影响已分配到此级别的员工(权限、提成)，请尽快为这些员工分配级别）`,
        torf: false,
        suc:()=>{
          axios.post('/share/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setPow(item){
      this.titleName=item.shareName
      this.shareId=item.id
      if(item.pow) this.pow=item.pow.split(',')
      else this.pow=[]
      this.powShow=true
    },
    setInfoData(item){
      this.title=item.shareName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/share/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增级别"
      this.MmsData.new=true
      this.infoShow=true
    },
    showInfo(){
      this.infoShow=true
    }
  },
}
</script>
