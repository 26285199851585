<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">级别名称：</span><input type="text" v-model="form.shareName"/></li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0">
                <option value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">指定比例加成（%）<i class="icon-alert-circle" title="加成是指指定员工提成后增加比例；&#10;例：某员工在某次工作中提成了10元，级别指定加成比例为10%，则这次提成为10+10*10%=11元&#10;指定加成只在消费项目中生效！"></i></div>
            <li class="up"><span>加成比例：</span><input type="text" v-model="form.tichengbaifenbi"/> %</li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">指定固定加成（元）<i class="icon-alert-circle" title="加成是指指定员工提成后增加固定金额；&#10;例：某员工在某次工作中提成了10元，级别指定加成比例为10%，固定提成加成金额为2元，则这次提成为10+2+10*10%=13元&#10;指定加成只在消费项目中生效！"></i></div>
            <li class="up"><span>加成金额：</span><input type="text" v-model="form.tichengguding"/> 元</li>
          </ul>
          <ul>
            <li class="up"><span>备注信息：</span><textarea v-model="form.mome"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {testStr,deepCopy} from '@/utils/util'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      storeList:[],
      dataList:[],
      form:{
        shareName:'',
        tichengbaifenbi:'0',
        tichengguding:'0',
        fenpeimendianId:'0',
        fenpeimendianName:'全部门店',
        mome:'',
      },
      defform:{
        shareName:'',
        tichengbaifenbi:'0',
        tichengguding:'0',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.jibieId==0?"全部门店":this.$store.state.User.fenpeimendianName,
        mome:'',
      }
    }
  },
  created() {this.init()},
  components: {},
  mounted() {},
  methods: {
    init(){
      this.getStore()
      if(this.MmsData.new){
        this.form=deepCopy(this.defform)
      }else{
        this.form=deepCopy(this.MmsData.data)
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName="全部门店"
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.shareName)){
        this.$Mesg({
          content: '级别名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      axios.post(this.MmsData.new?'/share/add':'/share/edit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({
            content: this.MmsData.new?'新增级别成功！':'修改级别成功！',
          })
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content:res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
